import { BrowserRouter } from 'react-router-dom';

// routes
import { AllRoutes } from './index';

import { AuthContextProvider } from '../pages/auth/AuthContext';

const Routes = () => {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>
    </AuthContextProvider>
  );
};

export default Routes;
