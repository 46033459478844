import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";

import { auth, db } from '../../firebase'
import { getAnalytics, setUserId } from "firebase/analytics";


import { doc, getDoc } from 'firebase/firestore';

const UserContext = createContext()


export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userInfo, setUserInfo] = useState([]);

    const analytics = getAnalytics();

    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };
 
    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    }

    const logout = () => {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser)
            if (currentUser) {
                const docRef = doc(db, 'Users', currentUser.uid);
                const docSnap = await getDoc(docRef);
                setUserInfo(docSnap.data())
                setUserId(analytics, currentUser.uid);
            }
            
        });
        return () => {
            unsubscribe();
        }
    }
        , [])

    return (
        <UserContext.Provider value={{ createUser, user, userInfo, logout, signIn }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}
