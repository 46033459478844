// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1j3tpp9iHlD30B7BTiHTxn9d26ek_pdw",
  authDomain: "shop-board-1e8e0-5dc0f.firebaseapp.com",
  projectId: "shop-board-1e8e0",
  storageBucket: "shop-board-1e8e0.firebasestorage.app",
  messagingSenderId: "747630402722",
  appId: "1:747630402722:web:8700b805bcb24452a38bfb",
  measurementId: "G-KTC0Y24D7T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, analytics, auth }