import { Navigate, useLocation } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

import { UserAuth } from '../pages/auth/AuthContext';


// hooks
import { useUser } from '../hooks';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
   
    const { user } =  UserAuth();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (!user) {
        return <Navigate to={'/auth/login'} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
